import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import type React from 'react'
import { useState } from 'react'
import {
  useDeleteNotificationMutation,
  useReadNotificationMutation
} from '~/api/mutations/user'
import { useBrandInfos } from '~/api/queries/global'
import type { UserNotification } from '~/api/queries/user'
import {
  notificationIcon,
  notificationIconVariants,
  notificationIconWrapper,
  notificationItem,
  notificationItemContent,
  notificationItemDate,
  notificationItemDeleteTrigger,
  notificationItemDeleteTriggerIcon,
  notificationItemDeleteWrapper,
  notificationItemSubtitle,
  notificationItemTitle,
  notificationItemTitleUnread,
  notificationItemUnreadDate,
  notificationTick,
  notificationUnreadTick
} from '~/components/Layout/Header/Notification/Notifications.css'
import { getNotificationMetas } from '~/components/Layout/Header/Notification/common'
import { formatRelativeTime } from '~/utils'

export const Notification = ({
  notification,
  userId,
  setCount
}: {
  notification: UserNotification
  userId: number
  setCount: React.Dispatch<React.SetStateAction<number>>
}) => {
  const { data } = useBrandInfos()
  const [isRead, setIsRead] = useState<boolean | null>(notification.isRead)
  const [timeoutId, setTimeoutId] = useState<number | null>(null)
  const readMutation = useReadNotificationMutation({
    userId
  })
  const deleteMutation = useDeleteNotificationMutation({
    userId
  })
  const legacyUrl = data?.legacyUrl ?? ''
  const { title, subtitle, icon, onClick } = getNotificationMetas(
    notification,
    legacyUrl
  )
  const onNotifClick = () => {
    if (!isRead) {
      setIsRead(true)
      setCount((count) => count - 1)
      readMutation.mutate({
        notificationId: notification.id
      })
    }
    onClick()
  }

  return (
    <motion.div
      variants={{
        initial: { opacity: 0, x: -20 },
        hidden: { opacity: 0, x: -20 },
        visible: { opacity: 1, x: 0 }
      }}
      className={notificationItem}
      onMouseEnter={() => {
        if (!isRead) {
          setTimeoutId(
            window.setTimeout(() => {
              setIsRead(true)
              setCount((count) => count - 1)
              readMutation.mutate({
                notificationId: notification.id
              })
            }, 1000)
          )
        }
      }}
      onMouseLeave={() => {
        if (timeoutId) {
          window.clearTimeout(timeoutId)
        }
      }}
    >
      <div className={notificationIconWrapper} onClick={onNotifClick}>
        {icon && (
          <span
            className={clsx(
              notificationIcon,
              notificationIconVariants[notification.type]
            )}
          >
            <FontAwesomeIcon icon={icon} />
          </span>
        )}
      </div>
      <div onClick={onNotifClick} className={notificationItemContent}>
        <div
          className={clsx(
            notificationItemTitle,
            isRead ? null : notificationItemTitleUnread
          )}
        >
          {title}
        </div>
        <div className={notificationItemSubtitle}>{subtitle}</div>
      </div>
      <div className={notificationItemDeleteWrapper}>
        <div
          className={clsx(
            notificationItemDate,
            isRead ? null : notificationItemUnreadDate
          )}
        >
          {formatRelativeTime(new Date(notification.updatedAt))}
        </div>
        <div className={notificationItemDeleteTrigger}>
          <FontAwesomeIcon
            icon={faXmark}
            onClick={(e) => {
              e.stopPropagation()
              if (!isRead) {
                setCount((count) => count - 1)
              }
              deleteMutation.mutate({
                notificationId: notification.id
              })
            }}
            className={notificationItemDeleteTriggerIcon}
          />
        </div>
      </div>
      <div className={notificationTick}>
        {!isRead ? <span className={notificationUnreadTick} /> : null}
      </div>
    </motion.div>
  )
}
