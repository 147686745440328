import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import type React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useBrandInfos } from '~/api/queries/global'
import type { RealTimeUserNotification } from '~/api/queries/user'
import {
  notificationFlashItem,
  notificationIcon,
  notificationIconVariants,
  notificationIconWrapper,
  notificationItem,
  notificationItemContent,
  notificationItemDeleteTriggerIcon,
  notificationItemFlashDeleteTrigger,
  notificationItemSubtitle,
  notificationItemTitle
} from '~/components/Layout/Header/Notification/Notifications.css'
import { getNotificationMetas } from '~/components/Layout/Header/Notification/common'
import {useReadNotificationMutation} from "~/api/mutations/user";

export const FlashNotification = ({
  notification,
  userId,
  setCount,
  onClose
}: {
  notification: RealTimeUserNotification
  userId: number
  setCount: React.Dispatch<React.SetStateAction<number>>
  onClose?: (slug: string) => void
}) => {
  const { data } = useBrandInfos()
  const [timeoutId, setTimeoutId] = useState<number | null>(null)
  const legacyUrl = data?.legacyUrl ?? ''
  const readMutation = useReadNotificationMutation({
    userId
  })
  const { title, subtitle, icon, onClick } = getNotificationMetas(
    notification,
    legacyUrl
  )

  const autoDismiss = useCallback(() => {
    if (!timeoutId) {
      setTimeoutId(
        window.setTimeout(() => {
          onClose?.(notification.slug)
        }, 10_000)
      )
    }
  }, [onClose, timeoutId, notification.slug])

  useEffect(() => {
    autoDismiss()
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
        setTimeoutId(null)
      }
    }
  }, [autoDismiss, timeoutId])

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      className={clsx(notificationItem, notificationFlashItem)}
      onMouseEnter={() => {
        if (timeoutId) {
          clearTimeout(timeoutId)
          setTimeoutId(null)
        }
      }}
      onMouseLeave={autoDismiss}
    >
      <div
        onClick={() => {
          onClose?.(notification.slug)
          readMutation.mutate({
            notificationId: notification.id
          })
          setCount((count) => count - 1)
          if (timeoutId) {
            clearTimeout(timeoutId)
          }
        }}
        className={notificationItemFlashDeleteTrigger}
      >
        <FontAwesomeIcon
          icon={faXmark}
          className={notificationItemDeleteTriggerIcon}
        />
      </div>
      <div className={notificationIconWrapper} onClick={onClick}>
        {icon && (
          <span
            className={clsx(
              notificationIcon,
              notificationIconVariants[notification.type]
            )}
          >
            <FontAwesomeIcon icon={icon} />
          </span>
        )}
      </div>
      <div onClick={onClick} className={notificationItemContent}>
        <div className={clsx(notificationItemTitle)}>{title}</div>
        <div className={notificationItemSubtitle}>{subtitle}</div>
      </div>
    </motion.div>
  )
}
