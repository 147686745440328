import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faMessage, faUserGraduate, faChartPie } from '@fortawesome/pro-solid-svg-icons'
import type { PropsWithChildren } from 'react'
import type {
  RealTimeUserNotification,
  UserNotification
} from '~/api/queries/user'

export const getNotificationMetas = (
  notification: UserNotification | RealTimeUserNotification,
  legacyUrl: string
): {
  title: string
  subtitle: string
  icon: null | IconDefinition
  onClick: () => void
} => {
  let title = ''
  let subtitle = ''
  let icon = null
  let onClick = () => {}
  switch (notification.type) {
    case 'application':
      title = `${notification.metadata.details.offerName}`
      subtitle = `Il y a ${notification.metadata.count} nouvelles candidatures sur l'offre`
      if (notification.metadata.count === 1) {
        subtitle = `${notification.metadata.stack[0]?.applicantName} a postulé sur l'offre`
      }
      if (!Object.hasOwn(notification, 'isRead')) {
        subtitle = `${notification.metadata.stack[notification.metadata.stack.length - 1]?.applicantName} a postulé sur l'offre`
      }
      onClick = () => {
        window.location.href = `${legacyUrl}/admin/jobmail/index/0/${notification.metadata.details.offerId}`
      }
      icon = faUserGraduate
      break

    case 'sms_reply':
      title = `SMS de ${notification.metadata.stack[0]?.applicantName}`
      subtitle = `${notification.metadata.stack[0]?.content}`
      if (notification.metadata.count > 1) {
        title = `${notification.metadata.count} SMS de ${notification.metadata.stack[0]?.applicantName}`
      }
      if (!Object.hasOwn(notification, 'isRead')) {
        title = `SMS de ${notification.metadata.stack[notification.metadata.stack.length - 1]?.applicantName}`
        subtitle = `${notification.metadata.stack[notification.metadata.stack.length - 1]?.content}`
      }
      onClick = () => {
        window.location.href = `${legacyUrl}/admin/sms/thread/${notification.metadata.details.threadId}/`
      }
      icon = faMessage
      break

    case 'stats_file':
      title = `Rapport ${notification.metadata.stack[0]?.name?.toLowerCase() ?? ''} disponible`
      subtitle = `Statistiques ${notification.metadata.stack[0]?.period ?? ''}`
      onClick = () => {
        window.location.href = `${legacyUrl}/admin/statistiques/config`
      }
      icon = faChartPie
      break
  }
  return { title, subtitle, icon, onClick }
}

export type NotificationsViewerProps = PropsWithChildren<{
  classname?: string
  currentUserId?: number
  defaultCount?: number
}>
