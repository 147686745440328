/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as BridgeImport } from './routes/bridge'
import { Route as LoginImport } from './routes/_login'
import { Route as DashboardImport } from './routes/_dashboard'
import { Route as LoginIndexImport } from './routes/_login/index'

// Create Virtual Routes

const LoginForgotPasswordLazyImport = createFileRoute(
  '/_login/forgot-password',
)()
const DashboardSettingsLazyImport = createFileRoute('/_dashboard/settings')()
const DashboardAssistantLazyImport = createFileRoute('/_dashboard/assistant')()
const DashboardOrdersIndexLazyImport = createFileRoute('/_dashboard/orders/')()
const DashboardOrdersOrderIdIndexLazyImport = createFileRoute(
  '/_dashboard/orders/$orderId/',
)()
const LoginChangePasswordInstanceTokenLazyImport = createFileRoute(
  '/_login/change-password/$instance/$token',
)()
const DashboardOrdersOrderIdHistoryLazyImport = createFileRoute(
  '/_dashboard/orders/$orderId/history',
)()

// Create/Update Routes

const BridgeRoute = BridgeImport.update({
  id: '/bridge',
  path: '/bridge',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/_login',
  getParentRoute: () => rootRoute,
} as any)

const DashboardRoute = DashboardImport.update({
  id: '/_dashboard',
  getParentRoute: () => rootRoute,
} as any)

const LoginIndexRoute = LoginIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => LoginRoute,
} as any)

const LoginForgotPasswordLazyRoute = LoginForgotPasswordLazyImport.update({
  id: '/forgot-password',
  path: '/forgot-password',
  getParentRoute: () => LoginRoute,
} as any).lazy(() =>
  import('./routes/_login/forgot-password.lazy').then((d) => d.Route),
)

const DashboardSettingsLazyRoute = DashboardSettingsLazyImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => DashboardRoute,
} as any).lazy(() =>
  import('./routes/_dashboard/settings.lazy').then((d) => d.Route),
)

const DashboardAssistantLazyRoute = DashboardAssistantLazyImport.update({
  id: '/assistant',
  path: '/assistant',
  getParentRoute: () => DashboardRoute,
} as any).lazy(() =>
  import('./routes/_dashboard/assistant.lazy').then((d) => d.Route),
)

const DashboardOrdersIndexLazyRoute = DashboardOrdersIndexLazyImport.update({
  id: '/orders/',
  path: '/orders/',
  getParentRoute: () => DashboardRoute,
} as any).lazy(() =>
  import('./routes/_dashboard/orders/index.lazy').then((d) => d.Route),
)

const DashboardOrdersOrderIdIndexLazyRoute =
  DashboardOrdersOrderIdIndexLazyImport.update({
    id: '/orders/$orderId/',
    path: '/orders/$orderId/',
    getParentRoute: () => DashboardRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/orders/$orderId/index.lazy').then(
      (d) => d.Route,
    ),
  )

const LoginChangePasswordInstanceTokenLazyRoute =
  LoginChangePasswordInstanceTokenLazyImport.update({
    id: '/change-password/$instance/$token',
    path: '/change-password/$instance/$token',
    getParentRoute: () => LoginRoute,
  } as any).lazy(() =>
    import('./routes/_login/change-password/$instance/$token.lazy').then(
      (d) => d.Route,
    ),
  )

const DashboardOrdersOrderIdHistoryLazyRoute =
  DashboardOrdersOrderIdHistoryLazyImport.update({
    id: '/orders/$orderId/history',
    path: '/orders/$orderId/history',
    getParentRoute: () => DashboardRoute,
  } as any).lazy(() =>
    import('./routes/_dashboard/orders/$orderId/history.lazy').then(
      (d) => d.Route,
    ),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_dashboard': {
      id: '/_dashboard'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/_login': {
      id: '/_login'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/bridge': {
      id: '/bridge'
      path: '/bridge'
      fullPath: '/bridge'
      preLoaderRoute: typeof BridgeImport
      parentRoute: typeof rootRoute
    }
    '/_dashboard/assistant': {
      id: '/_dashboard/assistant'
      path: '/assistant'
      fullPath: '/assistant'
      preLoaderRoute: typeof DashboardAssistantLazyImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/settings': {
      id: '/_dashboard/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof DashboardSettingsLazyImport
      parentRoute: typeof DashboardImport
    }
    '/_login/forgot-password': {
      id: '/_login/forgot-password'
      path: '/forgot-password'
      fullPath: '/forgot-password'
      preLoaderRoute: typeof LoginForgotPasswordLazyImport
      parentRoute: typeof LoginImport
    }
    '/_login/': {
      id: '/_login/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof LoginIndexImport
      parentRoute: typeof LoginImport
    }
    '/_dashboard/orders/': {
      id: '/_dashboard/orders/'
      path: '/orders'
      fullPath: '/orders'
      preLoaderRoute: typeof DashboardOrdersIndexLazyImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/orders/$orderId/history': {
      id: '/_dashboard/orders/$orderId/history'
      path: '/orders/$orderId/history'
      fullPath: '/orders/$orderId/history'
      preLoaderRoute: typeof DashboardOrdersOrderIdHistoryLazyImport
      parentRoute: typeof DashboardImport
    }
    '/_login/change-password/$instance/$token': {
      id: '/_login/change-password/$instance/$token'
      path: '/change-password/$instance/$token'
      fullPath: '/change-password/$instance/$token'
      preLoaderRoute: typeof LoginChangePasswordInstanceTokenLazyImport
      parentRoute: typeof LoginImport
    }
    '/_dashboard/orders/$orderId/': {
      id: '/_dashboard/orders/$orderId/'
      path: '/orders/$orderId'
      fullPath: '/orders/$orderId'
      preLoaderRoute: typeof DashboardOrdersOrderIdIndexLazyImport
      parentRoute: typeof DashboardImport
    }
  }
}

// Create and export the route tree

interface DashboardRouteChildren {
  DashboardAssistantLazyRoute: typeof DashboardAssistantLazyRoute
  DashboardSettingsLazyRoute: typeof DashboardSettingsLazyRoute
  DashboardOrdersIndexLazyRoute: typeof DashboardOrdersIndexLazyRoute
  DashboardOrdersOrderIdHistoryLazyRoute: typeof DashboardOrdersOrderIdHistoryLazyRoute
  DashboardOrdersOrderIdIndexLazyRoute: typeof DashboardOrdersOrderIdIndexLazyRoute
}

const DashboardRouteChildren: DashboardRouteChildren = {
  DashboardAssistantLazyRoute: DashboardAssistantLazyRoute,
  DashboardSettingsLazyRoute: DashboardSettingsLazyRoute,
  DashboardOrdersIndexLazyRoute: DashboardOrdersIndexLazyRoute,
  DashboardOrdersOrderIdHistoryLazyRoute:
    DashboardOrdersOrderIdHistoryLazyRoute,
  DashboardOrdersOrderIdIndexLazyRoute: DashboardOrdersOrderIdIndexLazyRoute,
}

const DashboardRouteWithChildren = DashboardRoute._addFileChildren(
  DashboardRouteChildren,
)

interface LoginRouteChildren {
  LoginForgotPasswordLazyRoute: typeof LoginForgotPasswordLazyRoute
  LoginIndexRoute: typeof LoginIndexRoute
  LoginChangePasswordInstanceTokenLazyRoute: typeof LoginChangePasswordInstanceTokenLazyRoute
}

const LoginRouteChildren: LoginRouteChildren = {
  LoginForgotPasswordLazyRoute: LoginForgotPasswordLazyRoute,
  LoginIndexRoute: LoginIndexRoute,
  LoginChangePasswordInstanceTokenLazyRoute:
    LoginChangePasswordInstanceTokenLazyRoute,
}

const LoginRouteWithChildren = LoginRoute._addFileChildren(LoginRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof LoginRouteWithChildren
  '/bridge': typeof BridgeRoute
  '/assistant': typeof DashboardAssistantLazyRoute
  '/settings': typeof DashboardSettingsLazyRoute
  '/forgot-password': typeof LoginForgotPasswordLazyRoute
  '/': typeof LoginIndexRoute
  '/orders': typeof DashboardOrdersIndexLazyRoute
  '/orders/$orderId/history': typeof DashboardOrdersOrderIdHistoryLazyRoute
  '/change-password/$instance/$token': typeof LoginChangePasswordInstanceTokenLazyRoute
  '/orders/$orderId': typeof DashboardOrdersOrderIdIndexLazyRoute
}

export interface FileRoutesByTo {
  '': typeof DashboardRouteWithChildren
  '/bridge': typeof BridgeRoute
  '/assistant': typeof DashboardAssistantLazyRoute
  '/settings': typeof DashboardSettingsLazyRoute
  '/forgot-password': typeof LoginForgotPasswordLazyRoute
  '/': typeof LoginIndexRoute
  '/orders': typeof DashboardOrdersIndexLazyRoute
  '/orders/$orderId/history': typeof DashboardOrdersOrderIdHistoryLazyRoute
  '/change-password/$instance/$token': typeof LoginChangePasswordInstanceTokenLazyRoute
  '/orders/$orderId': typeof DashboardOrdersOrderIdIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_dashboard': typeof DashboardRouteWithChildren
  '/_login': typeof LoginRouteWithChildren
  '/bridge': typeof BridgeRoute
  '/_dashboard/assistant': typeof DashboardAssistantLazyRoute
  '/_dashboard/settings': typeof DashboardSettingsLazyRoute
  '/_login/forgot-password': typeof LoginForgotPasswordLazyRoute
  '/_login/': typeof LoginIndexRoute
  '/_dashboard/orders/': typeof DashboardOrdersIndexLazyRoute
  '/_dashboard/orders/$orderId/history': typeof DashboardOrdersOrderIdHistoryLazyRoute
  '/_login/change-password/$instance/$token': typeof LoginChangePasswordInstanceTokenLazyRoute
  '/_dashboard/orders/$orderId/': typeof DashboardOrdersOrderIdIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/bridge'
    | '/assistant'
    | '/settings'
    | '/forgot-password'
    | '/'
    | '/orders'
    | '/orders/$orderId/history'
    | '/change-password/$instance/$token'
    | '/orders/$orderId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/bridge'
    | '/assistant'
    | '/settings'
    | '/forgot-password'
    | '/'
    | '/orders'
    | '/orders/$orderId/history'
    | '/change-password/$instance/$token'
    | '/orders/$orderId'
  id:
    | '__root__'
    | '/_dashboard'
    | '/_login'
    | '/bridge'
    | '/_dashboard/assistant'
    | '/_dashboard/settings'
    | '/_login/forgot-password'
    | '/_login/'
    | '/_dashboard/orders/'
    | '/_dashboard/orders/$orderId/history'
    | '/_login/change-password/$instance/$token'
    | '/_dashboard/orders/$orderId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  DashboardRoute: typeof DashboardRouteWithChildren
  LoginRoute: typeof LoginRouteWithChildren
  BridgeRoute: typeof BridgeRoute
}

const rootRouteChildren: RootRouteChildren = {
  DashboardRoute: DashboardRouteWithChildren,
  LoginRoute: LoginRouteWithChildren,
  BridgeRoute: BridgeRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_dashboard",
        "/_login",
        "/bridge"
      ]
    },
    "/_dashboard": {
      "filePath": "_dashboard.tsx",
      "children": [
        "/_dashboard/assistant",
        "/_dashboard/settings",
        "/_dashboard/orders/",
        "/_dashboard/orders/$orderId/history",
        "/_dashboard/orders/$orderId/"
      ]
    },
    "/_login": {
      "filePath": "_login.tsx",
      "children": [
        "/_login/forgot-password",
        "/_login/",
        "/_login/change-password/$instance/$token"
      ]
    },
    "/bridge": {
      "filePath": "bridge.tsx"
    },
    "/_dashboard/assistant": {
      "filePath": "_dashboard/assistant.lazy.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/settings": {
      "filePath": "_dashboard/settings.lazy.tsx",
      "parent": "/_dashboard"
    },
    "/_login/forgot-password": {
      "filePath": "_login/forgot-password.lazy.tsx",
      "parent": "/_login"
    },
    "/_login/": {
      "filePath": "_login/index.tsx",
      "parent": "/_login"
    },
    "/_dashboard/orders/": {
      "filePath": "_dashboard/orders/index.lazy.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/orders/$orderId/history": {
      "filePath": "_dashboard/orders/$orderId/history.lazy.tsx",
      "parent": "/_dashboard"
    },
    "/_login/change-password/$instance/$token": {
      "filePath": "_login/change-password/$instance/$token.lazy.tsx",
      "parent": "/_login"
    },
    "/_dashboard/orders/$orderId/": {
      "filePath": "_dashboard/orders/$orderId/index.lazy.tsx",
      "parent": "/_dashboard"
    }
  }
}
ROUTE_MANIFEST_END */
