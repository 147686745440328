import clsx from 'clsx'
import type React from 'react'
import type { ReactElement } from 'react'
import { themeClass } from '~/styles/theme.css'
import {
  button,
  buttonDisabled,
  buttonFullWidth,
  buttonInlined,
  buttonSmall,
  buttonVariants
} from './Button.css'
import { ButtonVariation } from './ButtonVariation'

export interface ButtonProps
  extends React.HTMLProps<HTMLButtonElement & HTMLAnchorElement> {
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement, MouseEvent>
  ) => void
  small?: boolean
  inlined?: boolean
  fullWidth?: boolean
  disabled?: boolean
  variation?: ButtonVariation
  children: React.ReactNode
  type?: 'button' | 'reset' | 'submit' | undefined
}

export const Button = ({
  variation = ButtonVariation.Primary,
  small = false,
  inlined = false,
  fullWidth = false,
  disabled = false,
  onClick = () => {},
  className,
  children,
  ...rest
}: ButtonProps): ReactElement => {
  const Tag = rest.href !== undefined ? 'a' : 'button'
  return (
    <Tag
      className={clsx(
        themeClass,
        button,
        small ? buttonSmall : null,
        inlined ? buttonInlined : null,
        fullWidth ? buttonFullWidth : null,
        disabled ? buttonDisabled : buttonVariants[variation],
        className
      )}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Tag>
  )
}
