import { faRightLeft } from '@fortawesome/pro-regular-svg-icons'
import {
  faCalendarDays,
  faExclamationTriangle,
  faListCheck,
  faMagicWandSparkles
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from '@tanstack/react-router'
import clsx from 'clsx'
import React, { type ReactElement } from 'react'
import { Shimmer } from '~/components/Global/Elements/Shimmer/Shimmer'
import { Tooltip } from '~/components/Global/Elements/Tooltip/Tooltip'
import { NotificationsViewer } from '~/components/Layout/Header/Notification/NotificationViewer'
import { themeClass } from '~/styles/theme.css'
import type { UserGroup } from '~/types/global'
import { GlobalSearch } from '../../Scopes/Search/GlobalSearch'
import { Menu, type MenuProps } from '../../Scopes/Users/Menu'
import {
  banner,
  brandLogo,
  brandSection,
  searchBlock,
  toolBar,
  toolBarTick,
  toolBaritem,
  toolBaritemActive, brandName
} from './Header.css'
import { UserSwitcher } from './UserSwitcher'

type HeaderProps = {
  brand?: {
    logo: string | null
    name: string
  }
  events?: number
  resumes?: number
  superUser?: boolean
  currentSection?: string
  legacyUrl?: string | null
  displaySearch?: boolean
  onChangeGroup?: (group: UserGroup) => void
  legacySwap?: { launch: () => void }
} & MenuProps

export const Header = ({
  brand,
  superUser = false,
  events,
  resumes,
  user,
  currentSection = '',
  legacyUrl = '',
  displaySearch = true,
  onChangeGroup,
  legacySwap
}: HeaderProps): ReactElement => {
  return (
    <div className={themeClass}>
      <header className={banner}>
        {brand !== undefined && (
          <a
            href={`${legacyUrl}/admin/accueil/dashboard`}
            className={brandSection}
          >
            {brand.logo !== null && (
              <img src={brand.logo} alt={brand.name} className={brandLogo} />
            )}
            <span className={brandName}>
              {brand.name}
            </span>
          </a>
        )}
        {brand === undefined && <Shimmer height={40} width={40} />}

        {displaySearch && (
          <div className={searchBlock}>
            {brand !== undefined && <GlobalSearch />}
            {brand === undefined && <Shimmer height={40} width={300} />}
          </div>
        )}

        {brand !== undefined && user !== undefined && (
          <div className={toolBar}>
            {superUser && legacySwap !== undefined && (
              <Tooltip
                direction="bottom"
                text="Changer d'utilisateur"
                as="span"
              >
                <span
                  className={toolBaritem}
                  onClick={() => legacySwap?.launch()}
                >
                  <FontAwesomeIcon icon={faRightLeft} />
                </span>
              </Tooltip>
            )}
            {legacySwap === undefined && (
              <Tooltip direction="bottom" text="Changer d'utilisateur">
                <UserSwitcher classname={toolBaritem}>
                  <FontAwesomeIcon icon={faRightLeft} />
                </UserSwitcher>
              </Tooltip>
            )}
            {window.JobConfig !== undefined && (
              <Tooltip direction="bottom" text="Assistant" as="span">
                <a
                  href={`${window.JobConfig.app_url}/assistant?jwt=${window.JobConfig.user.jwt}&instance=${window.JobConfig.instance.computedName}`}
                  title="Assistant"
                  className={clsx(
                    toolBaritem,
                    currentSection === 'assistant' ? toolBaritemActive : null
                  )}
                >
                  <FontAwesomeIcon icon={faMagicWandSparkles} />
                </a>
              </Tooltip>
            )}
            {window.JobConfig === undefined && (
              <Tooltip direction="bottom" text="Assistant" as="span">
                <Link
                  to="/assistant"
                  title="Assistant"
                  className={toolBaritem}
                  activeProps={{
                    className: clsx(toolBaritem, toolBaritemActive)
                  }}
                >
                  <FontAwesomeIcon icon={faMagicWandSparkles} />
                </Link>
              </Tooltip>
            )}
            {resumes !== undefined && resumes > 0 && (
              <Tooltip direction="bottom" text="CV à traiter" as="span">
                <a
                  href={`${legacyUrl}/admin/candidats/upload`}
                  className={toolBaritem}
                >
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  <span className={toolBarTick}>{resumes}</span>
                </a>
              </Tooltip>
            )}
            <Tooltip direction="bottom" text="Liste des tâches" as="span">
              <a
                title="Liste des tâches"
                href={`${legacyUrl}/admin/accueil/tasks`}
                className={clsx(
                  toolBaritem,
                  currentSection === 'tasks' ? toolBaritemActive : null
                )}
              >
                <FontAwesomeIcon icon={faListCheck} />
                <span />
              </a>
            </Tooltip>
            <Tooltip direction="bottom" text="Agenda" as="span">
              <a
                title="Agenda"
                href={`${legacyUrl}/admin/agenda`}
                className={clsx(
                  toolBaritem,
                  currentSection === 'calendar' ? toolBaritemActive : null
                )}
              >
                <FontAwesomeIcon icon={faCalendarDays} />
                {events !== undefined && events > 0 && (
                  <span className={toolBarTick}>{events}</span>
                )}
              </a>
            </Tooltip>
            <NotificationsViewer
              classname={toolBaritem}
              defaultCount={user.notificationCount}
              currentUserId={user.id}
            />
          </div>
        )}

        {brand === undefined && (
          <div className={toolBar}>
            <Shimmer height={40} width={200} />
          </div>
        )}
        <Menu user={user} legacyUrl={legacyUrl} onChangeGroup={onChangeGroup} />
      </header>
    </div>
  )
}
