import { faBell, faBellRing } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import type React from 'react'
import {
  type ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useBrandInfos } from '~/api/queries/global'
import {
  type RealTimeUserNotification,
  useCurrentUserNotifications
} from '~/api/queries/user'
import { Loader } from '~/components/Global/Elements/Loader/Loader'
import { container } from '~/components/Global/Interactions/Dropdown/Downdown.css'
import { Dropdown } from '~/components/Global/Interactions/Dropdown/Dropdown'
import {
  toolBarItemAttention,
  toolBarItemAttentionCount
} from '~/components/Layout/Header/Header.css'
import { FlashNotification } from '~/components/Layout/Header/Notification/FlashNotification'
import { Notification } from '~/components/Layout/Header/Notification/Notification'
import {
  notificationDropdown,
  notificationRTStack,
  notificationStack,
  notificationZone,
  notificationsPlaceholder,
  notificationsTitle,
  notificationsTitleCount
} from '~/components/Layout/Header/Notification/Notifications.css'
import type { NotificationsViewerProps } from '~/components/Layout/Header/Notification/common'
import { AuthContext } from '~/utils/contexts'
import Pusher from 'pusher-js'

export const NotificationsViewer = ({
  classname,
  currentUserId,
  defaultCount
}: NotificationsViewerProps): ReactElement => {
  const queryClient = useQueryClient()
  const [isOpen, setOpen] = useState<boolean>(false)
  const [count, setCount] = useState<number>(defaultCount ?? 0)
  const [realtimeNotifications, setRealtimeNotifications] = useState<
    RealTimeUserNotification[]
  >([])
  const [lastSlugsSeen, setLastSlugsSeen] = useState<
    {
      slug: string
      seenAt: Date
    }[]
  >([])
  const lastItemRef = useRef<HTMLDivElement | null>(null)
  const currentUser = useContext(AuthContext)
  const triggerRef = useRef<HTMLDivElement>(null)
  const notificationBellRef = useRef<SVGSVGElement>(null)
  const userId = Number(currentUser?.id ?? currentUserId)
  const {
    userNotifications,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = useCurrentUserNotifications({
    userId,
    active: isOpen
  })

  const bellAnimation: Keyframe[] = [
    { transform: 'rotate(10deg)' },
    { transform: 'rotate(-10deg)' }
  ]
  const bellAnimationOptions: KeyframeAnimationOptions = {
    duration: 300,
    iterations: 20,
    direction: 'alternate',
    easing: 'linear'
  }

  useEffect(() => {
    if (defaultCount !== undefined && defaultCount > 0 && notificationBellRef.current) {
      notificationBellRef.current.animate(bellAnimation, bellAnimationOptions)
    }
  }, [defaultCount])

  let instanceName: string | null =
    window.JobConfig?.instance.computedName ?? ''
  let pusherKey = window.JobConfig?.pusker_key
  let legacyUrl = ''

  if (window.JobConfig === undefined) {
    const { data: brandInfos } = useBrandInfos()
    instanceName = brandInfos?.slug ?? ''
    pusherKey = brandInfos?.pusherKey ?? ''
    legacyUrl = brandInfos?.legacyUrl ?? ''
  }

  useEffect(() => {
    if (!pusherKey) {
      return
    }
    const pusher = new Pusher(pusherKey, {
      cluster: 'eu',
      authEndpoint: `${legacyUrl}/accueil/pusher_auth`
    })
    const channel = pusher.subscribe(`private-${instanceName}-user-${userId}`)
    channel.bind('notification', (rawData: any) => {
      const notification: RealTimeUserNotification = JSON.parse(rawData)
      setCount(notification.unreadCount)
      void queryClient.invalidateQueries({
        queryKey: ['user', 'notifications', userId]
      })

      // Animate bell
      if (notificationBellRef.current) {
        notificationBellRef.current.animate(bellAnimation, bellAnimationOptions)
      }

      // Check seen slugs in last 15 minutes to avoid flooding
      const lastSeenSlugsGC = lastSlugsSeen.filter(
        (s) => s.seenAt.getTime() > Date.now() - 15 * 60 * 1000
      )
      if (lastSeenSlugsGC.find((n) => n.slug === notification.slug)) {
        return
      }
      setLastSlugsSeen(() => [
        ...lastSeenSlugsGC,
        { slug: notification.slug, seenAt: new Date() }
      ])
      setRealtimeNotifications((prev) => [...prev, notification])
    })
    return () => {
      pusher.disconnect()
    }
  }, [lastSlugsSeen, queryClient, userId, pusherKey, instanceName, legacyUrl])

  const endOfListReached = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting) {
          endOfListReached()
        }
      },
      { threshold: 0.5 }
    )
    if (lastItemRef.current) {
      observer.observe(lastItemRef.current)
    }
    return () => {
      if (lastItemRef.current) {
        observer.unobserve(lastItemRef.current)
      }
    }
  }, [endOfListReached])

  const onCloseFlashNotification = useCallback((slug: string) => {
    setRealtimeNotifications((prev) => prev.filter((n) => n.slug !== slug))
  }, [])

  return (
    <>
      <div
        className={clsx(
          classname,
          container,
          count > 0 ? toolBarItemAttention : null
        )}
        ref={triggerRef}
        onClick={() => {
          setOpen(!isOpen)
        }}
      >
        {count > 0 && (
          <span className={toolBarItemAttentionCount}>{count}</span>
        )}
        <FontAwesomeIcon
          icon={count > 0 ? faBellRing : faBell}
          ref={notificationBellRef}
        />
      </div>
      <div className={notificationZone}>
        <Dropdown
          isOpen={isOpen}
          onclose={() => setOpen(false)}
          showCloseTrigger={true}
          dropdownRef={triggerRef}
          className={notificationDropdown}
        >
          <div className={notificationsTitle}>
            Notifications{' '}
            <strong className={notificationsTitleCount}>
              ({Math.max(count, 0)})
            </strong>
          </div>
          {userNotifications?.length === 0 && (
            <div className={notificationsPlaceholder}>Aucune notification</div>
          )}
          <AnimatePresence>
            <motion.div
              className={notificationStack}
              variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1, transition: { staggerChildren: 0.1 } }
              }}
              initial="hidden"
              animate="visible"
            >
              {(userNotifications ?? []).map((notification) => (
                <Notification
                  key={notification.id}
                  notification={notification}
                  userId={userId}
                  setCount={setCount}
                />
              ))}
            </motion.div>
          </AnimatePresence>
          {isLoading && <Loader small={true} />}
          <div ref={lastItemRef} />
        </Dropdown>

        {/* Realtime notifications */}
        <div className={notificationRTStack}>
          <AnimatePresence>
            {realtimeNotifications.map((notification) => (
              <FlashNotification
                key={notification.slug}
                notification={notification}
                userId={userId}
                setCount={setCount}
                onClose={onCloseFlashNotification}
              />
            ))}
          </AnimatePresence>
        </div>
      </div>
    </>
  )
}
